import React from 'react';

const FateCharacterDisplay = ({ character }) => {
    if (!character) {
        return <p>No character data available.</p>;
    }

    const { name, highConcept, trouble, aspects, skills, stunts, stressTracks, consequences, appearance, backstory } = character;

    // Helper function to render skills by rank
    const renderSkills = () => (
        <div className="skills">
            <h3>Skills:</h3>
            <ul>
                <li><strong>Great:</strong> {skills.great}</li>
                <li><strong>Good:</strong> {skills.good.join(', ')}</li>
                <li><strong>Fair:</strong> {skills.fair.join(', ')}</li>
                <li><strong>Average:</strong> {skills.average.join(', ')}</li>
            </ul>
        </div>
    );

    // Helper function to render stunts
    const renderStunts = () => (
        <div className="stunts">
            <h3>Stunts:</h3>
            {stunts.map((stunt, index) => (
                <div key={index}>
                    <h4>{stunt.name}</h4>
                    <p>{stunt.description}</p>
                </div>
            ))}
        </div>
    );

    // Helper function to render stress tracks
    const renderStressTracks = () => (
        <div className="stress-tracks">
            <h3>Stress Tracks:</h3>
            <p><strong>Physical:</strong> {stressTracks.physical}</p>
            <p><strong>Mental:</strong> {stressTracks.mental}</p>
        </div>
    );

    // Helper function to render consequences
    const renderConsequences = () => (
        <div className="consequences">
            <h3>Consequences:</h3>
            <ul>
                <li><strong>Mild:</strong> {consequences.mild}</li>
                <li><strong>Moderate:</strong> {consequences.moderate}</li>
                <li><strong>Severe:</strong> {consequences.severe}</li>
            </ul>
        </div>
    );

    return (
        <div className="fate-character-container">
            <p><strong>High Concept:</strong> {highConcept}</p>
            <p><strong>Trouble:</strong> {trouble}</p>

            {/* Render Aspects */}
            <div className="aspects">
                <h3>Aspects:</h3>
                <ul>
                    {aspects.map((aspect, index) => (
                        <li key={index}>{aspect}</li>
                    ))}
                </ul>
            </div>

            {/* Render Skills */}
            {renderSkills()}

            {/* Render Stunts */}
            {renderStunts()}

            {/* Render Stress Tracks */}
            {renderStressTracks()}

            {/* Render Consequences */}
            {renderConsequences()}

            {/* Render Appearance */}
            <div className="appearance">
                <h3>Appearance:</h3>
                <p>{appearance}</p>
            </div>

            {/* Render Backstory */}
            <div className="backstory">
                <h3>Backstory:</h3>
                <p>{backstory}</p>
            </div>
        </div>
    );
};

export default FateCharacterDisplay;
