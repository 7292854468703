import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import '../App.css';

const SettingsPage = () => {
  const [settings, setSettings] = useState([]);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [editingSetting, setEditingSetting] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch settings on component mount
  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found, please log in.');

      const response = await axios.get('https://api.spawningpool.app/api/settings/view', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setSettings(response.data);
      setError(null);
    } catch (err) {
      setError(err.response ? err.response.data.message : err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateOrUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found, please log in.');

      if (editingSetting) {
        // Update existing setting
        await axios.put(
          `https://api.spawningpool.app/api/settings/edit/${editingSetting.user_setting_id}`,
          { user_setting_name: name, user_setting_description: description },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        setEditingSetting(null);
      } else {
        // Create new setting
        await axios.post(
          'https://api.spawningpool.app/api/settings/create',
          { user_setting_name: name, user_setting_description: description },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
      }

      // Reset form
      setName('');
      setDescription('');
      setError(null);
      fetchSettings();
    } catch (err) {
      setError(err.response ? err.response.data.message : err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (setting) => {
    setEditingSetting(setting);
    setName(setting.user_setting_name);
    setDescription(setting.user_setting_description);
  };

  const handleDelete = async (settingId) => {
    if (!window.confirm('Are you sure you want to delete this setting?')) return;

    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found, please log in.');

      await axios.delete(`https://api.spawningpool.app/api/settings/delete/${settingId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setError(null);
      fetchSettings();
    } catch (err) {
      setError(err.response ? err.response.data.message : err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelEdit = () => {
    setEditingSetting(null);
    setName('');
    setDescription('');
    setError(null);
  };

  return (
    <div className="setting-page">
      <h1>{editingSetting ? 'Edit Setting' : 'Manage Settings'}</h1>
      
      {/* Form for Creating or Editing */}
      <form onSubmit={handleCreateOrUpdate}>
        <div>
          <label htmlFor="name">Name: </label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            disabled={loading}
            style={{ width: '100%' }}
          />
        </div>

        <div>
          <label htmlFor="description">Description: </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows="4"
            required
            disabled={loading}
            style={{ width: '100%' }}
          />
        </div>

        <button className="generate" type="submit" disabled={loading}>
          {loading ? 'Processing...' : editingSetting ? 'Update Setting' : 'Create Setting'}
        </button>
        {editingSetting && (
          <button
            type="button"
            onClick={handleCancelEdit}
            className="generate"
            disabled={loading}
            style={{ marginLeft: '10px' }}
          >
            Cancel
          </button>
        )}
      </form>

      {/* Example or Instruction (optional) */}
      <div>
        <p>Example:</p>
        <p>* (Setting) "Dark Forest" - A mysterious forest filled with ancient magic and hidden dangers.</p>
      </div>

      {/* Loading Indicator */}
      {loading && <div className="spinner">Loading...</div>}

      {/* Error Message */}
      {error && (
        <div>
          <h2>Error:</h2>
          <p>{error}</p>
        </div>
      )}

      {/* Settings List */}
      <div>
        <h2>Your Settings:</h2>
        {settings.length === 0 ? (
          <p>No settings found.</p>
        ) : (
          <table className="settings-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Name</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Description</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {settings.map((setting) => (
                <tr key={setting.user_setting_id}>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{setting.user_setting_name}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    <div className='scrollable-content'>
                      <ReactMarkdown>{setting.user_setting_description}</ReactMarkdown>
                    </div>
                  </td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                    <button
                      onClick={() => handleEdit(setting)}
                      className="generate"
                      disabled={loading}
                      style={{ marginRight: '10px' }}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(setting.user_setting_id)}
                      className="generate"
                      disabled={loading}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default SettingsPage;
