import React from 'react';
import ReactDOM from 'react-dom';
import './ImageModal.css';

const ImageModal = ({ src, alt, onClose }) => {
    return ReactDOM.createPortal(
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <img
                    src={src}
                    alt={alt}
                    className="modal-image"
                />
            </div>
        </div>,
        document.body // Render modal in the body
    );
};

export default ImageModal;
