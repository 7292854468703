import React, { useState, useEffect } from 'react';
import ImageModal from './ImageModal';  // Import the modal component

const ImageWithLoader = ({ src, alt }) => {
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        // Whenever the src changes, reset the loading state to true
        setLoading(true);
    }, [src]);

    const handleImageLoad = () => {
        setLoading(false);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div className="image-container">
                {loading && <div className="loading-bar">Loading image...</div>} {/* Loading indicator */}
                <img 
                    src={src} 
                    alt={alt} 
                    onLoad={handleImageLoad} 
                    onClick={openModal} 
                    style={{ 
                        border: '2px solid blue',
                        display: loading ? 'none' : 'block', 
                        cursor: 'pointer',
                    }} 
                />
                {isModalOpen && <ImageModal src={src} alt={alt} onClose={closeModal} />}
            </div>
        </>
    );
};

export default ImageWithLoader;
