// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ImageModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: transparent;
    padding: 0;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    overflow: hidden;
}

.modal-image {
    width: 100%; /* Ensure the image in the modal takes up the full width of the modal */
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/components/ImageModal.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,uBAAuB;IACvB,UAAU;IACV,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,WAAW,EAAE,uEAAuE;IACpF,YAAY;IACZ,kBAAkB;IAClB,wCAAwC;AAC5C","sourcesContent":["/* ImageModal.css */\r\n.modal-overlay {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    background-color: rgba(0, 0, 0, 0.7);\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    z-index: 1000;\r\n}\r\n\r\n.modal-content {\r\n    background: transparent;\r\n    padding: 0;\r\n    border-radius: 8px;\r\n    max-width: 90%;\r\n    max-height: 90%;\r\n    overflow: hidden;\r\n}\r\n\r\n.modal-image {\r\n    width: 100%; /* Ensure the image in the modal takes up the full width of the modal */\r\n    height: auto;\r\n    border-radius: 8px;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
