// FeatureDisplay.js
import React from 'react';

const FeatureDisplay = ({ feature }) => {
    if (!feature) {
        return <p>No feature data available</p>;
    }

    return (
        <div className="feature-container">
            <h2>{feature.name}</h2>
            <p><strong>Description:</strong> {feature.description}</p>
            <p><strong>Appearance:</strong> {feature.appearance}</p>
        </div>
    );
};

export default FeatureDisplay;
