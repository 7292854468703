import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import StartPage from './components/prompt';
import UserCreationsDisplay from './UserCreationsDisplay';
import Login from './login';
import SettingsPage from './components/setting';

// Helper function to check if the user is logged in
const isAuthenticated = () => {
  return localStorage.getItem('token') !== null;
};

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // If the user is not logged in, redirect to login
  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login');
    }
  }, [navigate]);

  const handleTabClick = (path) => {
    navigate(path); // Navigate to the selected route
  };

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove the token from localStorage
    navigate('/login'); // Redirect to the login page
  };

  const showTabs = isAuthenticated() && location.pathname !== '/login';

  return (
    <div className="App">
      {showTabs && (
        <div className="tabs-container">
          <div className="tabs">
            <button
              onClick={() => handleTabClick('/prompt')}
              className={`tab-button ${window.location.pathname === '/prompt' ? 'active' : ''}`}
            >
              Prompt
            </button>
            <button
              onClick={() => handleTabClick('/display')}
              className={`tab-button ${window.location.pathname === '/display' ? 'active' : ''}`}
            >
              View Creations
            </button>
            <button
              onClick={() => handleTabClick('/settings')}
              className={`tab-button ${window.location.pathname === '/settings' ? 'active' : ''}`}
            >
              Settings
            </button>
          </div>
          <button className="logout-button" onClick={handleLogout}>
            Logout
          </button>
        </div>
      )}

      <Routes>
        <Route path="/prompt" element={<StartPage />} />
        <Route path="/display" element={<UserCreationsDisplay />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </div>
  );
};

export default App;
