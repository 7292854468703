import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SettlementDisplay from './components/SettlementDetailSheet'; 
import FactionDisplay from './components/FactionDetailSheet';
import FateCharacterDisplay from './components/FateCharacterDetailSheet';
import ImageWithLoader from './components/ImageWithLoader'; 
import FeatureDisplay from './components/FeatureDetailSheet';
import './App.css';

const UserCreationsDisplay = () => {
    const [creations, setCreations] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isIndexVisible, setIsIndexVisible] = useState(true); 
    const [searchTerm, setSearchTerm] = useState('');
    const [filterType, setFilterType] = useState('');

    useEffect(() => {
        const fetchCreations = async () => {
            try {
                const token = localStorage.getItem('token'); // Retrieve the JWT token

                if (!token) {
                    setError("No token found");
                    return;
                }

                // Make the authenticated request to the backend
                const response = await axios.get(`https://api.spawningpool.app/GetCreations`, {
                    headers: {
                        'Authorization': `Bearer ${token}` // Pass the JWT token in the request header
                    }
                });

                setCreations(response.data);
            } catch (err) {
                setError(err);
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchCreations();
    }, []);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % creations.length);
    };

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + creations.length) % creations.length);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());  // Update search term
    };

    const handleFilterChange = (e) => {
        setFilterType(e.target.value);  // Update filter
    };

    const handleSelectCreation = (index) => {
        // Find the index of the selected creation in the full `creations` list
        const fullIndex = creations.findIndex((creation) => creation === filteredCreations[index]);
        setCurrentIndex(fullIndex);
    };

    const toggleIndexVisibility = () => {
        setIsIndexVisible(!isIndexVisible);
    };

    // Apply search and filter logic to the creations for the index panel only
    const filteredCreations = creations
        .filter((creation) => creation.createdObject.name.toLowerCase().includes(searchTerm))
        .filter((creation) => filterType === '' || creation.creationType === parseInt(filterType));

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading creations: {error.message}</p>;
    if (!creations.length) return <p>No creations found.</p>;

    // Get the current creation from the full `creations` list
    const currentCreation = creations[currentIndex];
    const { creationType, createdObject } = currentCreation;

    if (!currentCreation || !createdObject) {
        return <p>Invalid creation data.</p>;
    }

    return (
        <div className="user-creations-display">
            <button className="toggle-index-btn" onClick={toggleIndexVisibility}>
                {isIndexVisible ? '<' : '>'}
            </button>
            {isIndexVisible && (
                <div className="index-panel">
                    <h3>Creation Index</h3>

                    <input
                        type="text"
                        placeholder="Search by name"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="search-input"
                    />

                    <select value={filterType} onChange={handleFilterChange} className="filter-select">
                        <option value="">All Types</option>
                        <option value="1">Settlement</option>
                        <option value="2">Faction</option>
                        <option value="3">Feature</option>
                        <option value="4">5e Character</option>
                        <option value="5">FATE Character</option>
                    </select>

                    <ul>
                        {filteredCreations.map((creation, index) => (
                            <li
                                key={index}
                                onClick={() => handleSelectCreation(index)}  // Handle click to update current index
                                className={creations[currentIndex] === creation ? 'active' : ''}
                            >
                                {creation.createdObject.name} ({creationTypeToName(creation.creationType)})
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            <div className="main-content">
                <div className="navigation-buttons">
                        <button onClick={handlePrevious}>&laquo; Previous</button>
                        <button onClick={handleNext}>Next &raquo;</button>
                </div>
                <div className="middle-container">
                    <>
                        <h1>{createdObject.name}</h1>
                        {currentCreation.id && (
                            <ImageWithLoader 
                                src={`https://api.spawningpool.app/GetImage/${currentCreation.id}`} 
                                alt={createdObject.name} 
                            />
                        )}
                    </>
                    {creationType === 1 && <SettlementDisplay settlement={createdObject} />}
                    {creationType === 2 && <FactionDisplay faction={createdObject} />}
                    {creationType === 3 && <FeatureDisplay feature={createdObject} />}
                    {creationType === 5 && <FateCharacterDisplay character={createdObject} />}
                </div>
            </div>
        </div>
    );
};

// Helper function to convert creationType to a readable name
const creationTypeToName = (type) => {
    switch (type) {
        case 1: return 'Settlement';
        case 2: return 'Faction';
        case 3: return 'Feature';
        case 4: return 'Character (5e)';
        case 5: return 'Character (FATE)';
        case 6: return 'NPC (5e)';
        case 7: return 'Character (Soup)';
        default: return 'Unknown';
    }
};

export default UserCreationsDisplay;
