import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './App.css'; // Importing your CSS file

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isRegistering, setIsRegistering] = useState(false); // Toggle between login and registration
  const navigate = useNavigate();  // useNavigate to redirect

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.spawningpool.app/login', { username, password }, { withCredentials: true });
      const token = response.data.token;
      localStorage.setItem('token', token);
      navigate('/prompt'); // Redirect after successful login
    } catch (err) {
      setError('Login failed. Please check your credentials.');
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    try {
      // Call the backend to create a new user
      const response = await axios.post('https://api.spawningpool.app/newuser', {
        username,
        password
      }, { withCredentials: true });
      console.log(response.data);  // Show success message or handle errors

      // Auto-login the user after creation
      handleLoginSubmit(e);
    } catch (err) {
      setError('Registration failed. Username might be taken.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h1>SPAWNING POOL</h1>
        <h2>{isRegistering ? 'Create New Account' : 'Login'}</h2>
        <form onSubmit={isRegistering ? handleRegisterSubmit : handleLoginSubmit}>
          <div className="input-container">
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              className="login-input"
            />
          </div>
          <div className="input-container">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="login-input"
            />
          </div>
          <button type="submit" className="login-button">{isRegistering ? 'Register' : 'Login'}</button>
        </form>
        {error && <p className="error-message">{error}</p>}

        {/* Toggle between login and register */}
        <p>
          {isRegistering
            ? 'Already have an account? '
            : "Don't have an account yet? "}
          <button className="toggle-link" onClick={() => setIsRegistering(!isRegistering)}>
            {isRegistering ? 'Login' : 'Create an Account'}
          </button>
        </p>
      </div>
    </div>
  );
};

export default Login;
