import React, { useState } from 'react';

const FactionDisplay = ({ faction }) => {
    const [showFactions, setShowFactions] = useState(false);
    const [showFeatures, setShowFeatures] = useState(false);

    if (!faction) {
        return <p>No faction data available</p>;
    }

    const { name, description, appearance, memberCount, children } = faction;

    // Filter children into factions and features
    const factionMembers = children?.filter(child => child.type === 3);
    const features = children?.filter(child => child.type === 4);

    // Helper function to render faction members
    const renderFactionMembers = () => (
        factionMembers?.map((child, index) => (
            <div key={index} className="child">
                <h4>{child.name} (Character)</h4>
                <p><strong>Appearance:</strong> {child.appearance}</p>
                <p><strong>Description:</strong> {child.description}</p>
            </div>
        ))
    );

    // Helper function to render features
    const renderFeatures = () => (
        features?.map((child, index) => (
            <div key={index} className="child">
                <h4>{child.name} (Feature)</h4>
                <p><strong>Appearance:</strong> {child.appearance}</p>
                <p><strong>Description:</strong> {child.description}</p>
            </div>
        ))
    );

    return (
        <div className="faction-container">
            <p><strong>Description:</strong> {description}</p>
            <p><strong>Appearance:</strong> {appearance}</p>
            {memberCount && <p><strong>Member Count:</strong> {memberCount}</p>}

            {/* Factions Accordion */}
            <div className="accordion">
                <button onClick={() => setShowFactions(!showFactions)}>
                    {showFactions ? 'Hide Faction Members' : 'Show Faction Members'}
                </button>
                {showFactions && (
                    <div className="accordion-content">
                        {factionMembers?.length ? renderFactionMembers() : <p>No faction members available.</p>}
                    </div>
                )}
            </div>

            {/* Features Accordion */}
            <div className="accordion">
                <button onClick={() => setShowFeatures(!showFeatures)}>
                    {showFeatures ? 'Hide Features' : 'Show Features'}
                </button>
                {showFeatures && (
                    <div className="accordion-content">
                        {features?.length ? renderFeatures() : <p>No features available.</p>}
                    </div>
                )}
            </div>
        </div>
    );
};

export default FactionDisplay;
