import React from 'react';

const SettlementDisplay = ({ settlement }) => {
    if (!settlement) {
        return <p>No settlement data available</p>;
    }

    const { name, description, appearance, demographics, children } = settlement;
    console.log('Demographics:', demographics);
    // Helper function to render demographics
    const renderDemographics = () => {
        if (!demographics) return null;

        return (
            <div className="demographics">
                <h3>Demographics:</h3>
                <ul>
                    {Object.entries(demographics).map(([species, percentage]) => (
                        <li key={species}>{species}: {percentage}%</li>
                    ))}
                </ul>
            </div>
        );
    };

    // Helper function to render child objects (Factions or Features)
    const renderChildren = () => {
        if (!children || children.length === 0) return null;

        return (
            <div className="children">
                <h3>Factions and Features:</h3>
                {children.map((child, index) => (
                    <div key={index} className="child">
                        <h4>{child.name} ({child.type === 2 ? 'Faction' : 'Feature'})</h4>
                        <p><strong>Appearance:</strong> {child.appearance}</p>
                        <p><strong>Description:</strong> {child.description}</p>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="settlement-container">
            <p><strong>Description:</strong> {description}</p>
            <p><strong>Appearance:</strong> {appearance}</p>

            {renderDemographics()}
            {renderChildren()}
        </div>
    );
};

export default SettlementDisplay;
