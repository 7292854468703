import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../App.css'

const StartPage = () => {
  const [source, setSource] = useState('chatGPT');
  const [detailType, setDetailType] = useState('feature');
  const [characterSheetType, setCharacterSheetType] = useState('fate');
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    let endpoint = '';
    if (detailType === 'feature') {
      endpoint = '/feature';
    } else if (detailType === 'settlement') {
      endpoint = '/settlement';
    } else if (detailType === 'faction') {
      endpoint = '/faction';
    } else if (detailType === 'character') {
      switch (characterSheetType) {
        case '5e':
          endpoint = '/5e';
          break;
        case 'fate':
          endpoint = '/fate';
          break;
        case 'soup':
          endpoint = '/soup-hunt';
          break;
        case '5e NPC':
          endpoint = '/5e-npc';
          break;
        default:
          endpoint = '/fate';
          break;
      }
    }
  
    try {
      const token = localStorage.getItem('token');
  
      if (!token) {
        throw new Error('No token found, please log in.');
      }
  
      const res = await axios.post(
        `https://api.spawningpool.app/api/detailsheet${endpoint}`,
        prompt,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        }
      );
  
      setLoading(false);
      if (res.status === 200) { 
        navigate('/display');
      }
      setResponse(res.data);
      setError(null);
    } catch (err) {
      setLoading(false);
      setError(err.response ? err.response.data.message : err.message);
      setResponse(null);
    }
  };
  

  return (
    <div className="start-page">
      <h1>What do you wish for?</h1>
      <form onSubmit={handleSubmit}>
        {/*
        <div>
          <label htmlFor="source">Source: </label>
          <select id="source" value={source} onChange={(e) => setSource(e.target.value)} disabled={loading}>
            <option value="local" disabled>Local (Not running)</option>
            <option value="chatGPT">ChatGPT</option>
          </select>
        </div>
        */}
        <div>
          <label htmlFor="detailType">Detail Sheet Type: </label>
          <select id="detailType" value={detailType} onChange={(e) => setDetailType(e.target.value)} disabled={loading}>
            <option value="feature">Feature</option>
            <option value="character">Character</option>
            <option value="settlement">Settlement</option>
            <option value="faction">Faction</option>
          </select>
        </div>

        {detailType === 'character' && (
          <div>
            <label htmlFor="characterSheetType">Character Sheet Type: </label>
            <select
              id="characterSheetType"
              value={characterSheetType}
              onChange={(e) => setCharacterSheetType(e.target.value)}
              disabled={loading}
            >
              <option value="fate">FATE</option>
              <option value="5e" disabled>5e (coming soon)</option>
              <option value="soup" disabled>Soup (coming soon)</option>
              <option value="5e NPC" disabled>5e NPC (coming soon)</option>
            </select>
          </div>
        )}

        <div>
          <label htmlFor="prompt">Prompt: </label>
          <textarea
            id="prompt"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            rows="6" 
            disabled={loading}
            style={{ width: '100%' }}
          />
        </div>

        <button className="generate" type="submit" disabled={loading}>
          {loading ? 'Generating...' : 'Generate'}
        </button>
        <div>
          <p>Example prompt:</p>
          {detailType === 'character' && (<p>* (Character) A young adventurer in an urban fantasy setting who is seeking glory</p>)}
          {detailType === 'feature' && (<p>* (Feature) A magic ring that grants its user a useful magic power at a cost.</p>)}
          {detailType === 'faction' && (<p>* (Faction) A warband of a local orcish tribe that loves to feast on their fallen enemies' intestines </p>)}
          {detailType === 'settlement' && (<p>* (Settlement) The last campsite of the War-King's territory, adjacent to an entrance to the underdark as well as the great dark ocean.</p>)}
        </div>
      </form>

      {loading && <div className="spinner">Loading...</div>}

      {response && (
        <div>
          <h2>Response:</h2>
          <p>{response}</p>
        </div>
      )}
      {error && (
        <div>
          <h2>Error:</h2>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default StartPage;
